<!--------------------------------------------
| TABELA DE LISTAGEM DE DOADORES
|-------------------------------------------->

<template>
    <div>
      <v-card class="rounded-lg" elevation="4">
        <v-tabs fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab>PACIENTES DOADORES</v-tab>
 
            <v-tab-item>
              <v-card-text>
                <v-form ref="searchDonor">
                  <p class="space-personalized"></p>
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="9">
                        <v-text-field
                        color="#086318" 
                        label="Nome" 
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorSearch.name"
                        dense></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        label="RGCT" 
                        placeholder="RGCT" 
                        outlined 
                        v-model="donorSearch.rgct"
                        v-mask="'##############'" 
                        maxlength="14" 
                        dense></v-text-field>
                      </v-col>

                      <!-- <v-col class="d-flex" cols="12" sm="4">
                        <v-text-field 
                        color="#086318" 
                        label="Nome da mãe" 
                        placeholder="Nome da mãe" 
                        outlined
                        v-model="donorSearch.mother_name" 
                        dense></v-text-field>
                      </v-col> -->
                    </v-row>

                    <p class="space-personalized"></p>

                    <v-row v-if="disableButton == false">
                      <div class="space"></div>
                      
                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="green" 
                        @click="search()">
                        <span class="textBtn"> Pesquisar </span>
                      </v-btn>

                      <div class="space"></div>
                      
                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="grey" 
                        @click="clear()">
                          <span class="textBtn"> Limpar </span>
                      </v-btn>

                      <div class="space"></div>

                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="blue" 
                        @click="$router.push('/cadastrar-doador')" 
                        v-if="((this.$store.state.profile_id != 5))">

                          <v-icon
                          class="mr-2" 
                          color="#FFFFFF">mdi-hand-heart</v-icon>

                          <span class="textBtn"> Adicionar Doador </span>
                      </v-btn>
                      
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
  
              <!-- Início da Tabela -->
              <v-data-table
                :headers="headers"
                :items="donor"
                :footer-props="{'items-per-page-text': 'Doador por Página',}"
                :page="page"
                :loading="loading">

                <template v-slot:[`item.value`]="{ item }">
                  <TagCaptacao :resource="item"/>
                </template>

                <template v-slot:[`item.actions`]="{ item }" :resource="item">
                  <v-tooltip left color="#3e6943" v-if="(($store.state.profile_id != 5))">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        size="20"
                        class="icon"
                        color="#3e6943" 
                        align-item="center"
                        v-on="on"
                        v-bind="attrs"
                      @click="$router.push({path: '/cadastrar-exames', query:{item:item}})">mdi-plus-circle
                      </v-icon>
                    </template>
                    <span style="color:white">Cadastrar Exames</span>
                  </v-tooltip>
                  <v-tooltip bottom color="#3fa118">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        size="24"
                        class="icon"
                        color="#3fa118" 
                        align-item="center"
                        v-on="on"
                        v-bind="attrs"
                      @click="$router.push({path: '/visualizar-doador', query:{item:item}})">mdi-eye 
                      </v-icon>
                    </template>
                    <span style="color:white">Visualizar Doador</span>
                  </v-tooltip>
                  <v-tooltip bottom color="#2ea8ff" v-if="(($store.state.profile_id != 5))">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        size="24"
                        class="icon"
                        color="#2ea8ff" 
                        align-item="center"
                        v-on="on"
                        v-bind="attrs"
                        @click="$router.push({path: '/editar-doador', query:{item:item}})">mdi-pencil 
                      </v-icon>
                    </template>
                    <span style="color:white">Editar Doador</span>
                  </v-tooltip>
                  <v-tooltip right color="#e66832" v-if="(($store.state.profile_id != 5))">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        size="24"
                        class="icon"
                        color="#e66832" 
                        align-item="center"
                        v-on="on"
                        v-bind="attrs"
                        :disabled="item.refused != null"
                        @click="$router.push({path: '/cadastrar-captacao'})">mdi-heart-plus
                      </v-icon>
                    </template>
                    <span style="color:white">Inserir Captação</span>
                  </v-tooltip>
                </template>
            
              </v-data-table>
           </v-tab-item>
      </v-tabs>

      <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>

  </v-card>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import TagCaptacao from "./TagCaptacao.vue"

  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Doador",
            align: "center",
            value: "name",
          },
          { text: "Captação", value: "value", sortable: false ,  align: "center", },
          { text: "Ações", value: "actions", sortable: false ,  align: "center", },
        ],
        disableCaptation: true,
        donorSearch: {
          name:"",
          rgct:""
        },

        select: {
          state:'Vivo(a)',
          id:1
        },

        items: [
          {
            state: 'Vivo(a)',
            id: 1
          },
          {
            state: 'Morto(a)',
            id: 2
          }
        ],
  
        displayError: {
          display: false,
          message: [],
        },
        
        totalDonors: " ",
        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        filterUnity: "",
        filtro: {},
        donor: [],
        usuarios: [],
        unidades: [],
        hospital: [],
        message: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        disableButton: false,
        messageError: "",
        donorActiveId: "",
      };
    },
  
    components: {
      TagCaptacao
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    computed: {
      doadorAtivo(){
          return this.$route.query.id
      },
    },

    created() {
      this.indoDataTable()
    },

    methods: {
      indoDataTable() {
        this.loading = true;
        if(this.$store.state.profile_id == 1){
          axios.get(`/donor`).then((response) => {
            this.loading = false;
            this.donor = response.data.data;
            this.donor = this.donor.filter((x)=> x.kind_of_donor_id != 1)
          });
        }else{
          const id = this.$store.state.unity_id
          axios.get(`/donor/donorUnity/${id}`).then((response) => {
            this.loading = false;
            this.donor = response.data.data
            this.donor = this.donor.filter((x)=> x.kind_of_donor_id != 1)
          })
        }
      },
  
      getColor(item) {
        if(item.captation){
          return '#e66832'
        }else{
          return '#c7c3c1'
        }
      },

      search() {
        if(this.$store.state.profile_id == 1){
          var filtro = (this.donorSearch)
            axios.post("/donor/filter", filtro).then((response) => {
              this.loading = true;
              this.donor = response.data.data; 
              this.loading = false;
            });
        }else{
          let filtro = (this.donorSearch)
          axios.post("/donor/filter", filtro).then((response) => {
            this.loading = true;
            this.donor = response.data.data; 
            this.donor = this.donor.filter((x)=> x.unity_id == this.$store.state.unity_id);
            this.loading = false;
          });
        }
      },

      clear(){
        this.donorSearch.name = null,
        this.donorSearch.rgct = null,
        this.indoDataTable();
        this.$emit("eventname", '');
      },

    },
  
    mounted() {
      this.indoDataTable();
    },
    
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  